<template>
  <div class="details">
    <!-- 头部 -->
    <van-nav-bar fixed placeholder z-index="100" :title="list.productName" left-text="返回" right-text="首页" left-arrow @click-left="onClickLeft" @click-right="onClickRight"/>
    <!-- 轮播图 -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item,index) in swipearr" :key="item.id">
        <img :src="item" alt="" @click="sceneImg(swipearr,index)" v-lazy="item">
      </van-swipe-item>
    </van-swipe>
    <!-- 价格信息 -->
<!--    <div class="priceInformation" v-if="list.labelList">-->
    <div class="priceInformation">
        <p><span>￥{{list.specialPrices==null? list.productPrices:list.specialPrices}}</span><span v-show="list.specialPrices" class="active">￥{{list.productPrices}}</span></p>
<!--        <p>{{list.productName}} | {{list.labelList[0].labelContent}}</p>-->
        <p>{{list.productName}}</p>
        <p>作者：<span>{{authorList.authorName}}</span>
               <span>
                 <span class="follow" @click="clickFollowshow" v-if="isshow == 1">关注</span> 
                 <span class="Concerned" v-else-if=" isshow == 2"  @click="clickFollowshow"><img src="~@image/dui.png" alt="">已关注</span>
                 <span class="mutual" v-else @click="clickFollowshow"><img src="~@image/huxiangguanzhu.png" alt="">互相关注</span>
        </span></p>
    </div>
    <!-- tab切换 -->
    <van-tabs v-model="active" animated color="#4587FF" title-active-color="#4587FF">
      <!-- 数据库简介 -->
      <van-tab title="数据库简介" name="sql-server">
          <div class="text" v-html="list.productDescription"></div>
      </van-tab>

      <!-- 作者简介 -->
      <van-tab title="作者简介" name="author" class="author">
        <div class="text" v-html="authorList.authorIntroduce"></div>
        <video ref="video" :src="authorList.authorVideoPath" controls preload="none">您的浏览器不支持video元素</video>
        <div class="images">
           <img v-for="(item,index) in imgarr" :key="item.id"  :src="item" alt="" @click="sceneImg(imgarr,index)">
        </div>
      </van-tab>

      <!-- 用户评论 -->
      <van-tab title="用户评论" name="comment">
          <div class="kong" v-if="commentList.length == 0 || commentList =='' || commentList == undefined">
              暂无评论
          </div>
          <ul class="list" v-else >
            <li class="item" v-for="(item,index) in commentList" :key="item.id" :class="index==0?'':'top'">
                  <div class="item-header">
                     <van-image
                      round
                      cover
                      fit="cover"
                      @click.stop="jumppersonal(item.userId)"
                      :src="item.userHeadImg?item.userHeadImg :'https://i.loli.net/2021/11/03/bDNUWZFYLO76kjJ.png'"
                    />
                    <p>{{item.nickName}}</p>
                    <p>{{item.createTime}}</p>
                    <van-rate v-model="item.score" :size="10" readonly color="#ffd21e" void-icon="star" void-color="#eee"/>
                  </div>
                  <div class="item-content">
                     <div class="txt">
                       {{item.content}}
                     </div>
                     <div class="imgpic" v-if="item.evaluateImg.length !== 0">
                        <img :src="val" alt="" v-for="(val,index) in item.evaluateImg" :key="index" @click.stop="sceneImg(item.evaluateImg,index)" v-lazy="val">
                     </div>
                  </div>
                  <div class="item-footer">
                      <div class="right">
                          <span @click.stop="Report(item)">举报</span>
                          <span @click.stop="spotParise(item)"><span :class="item.endorse==false?'hui':'red'"></span>赞({{item.countPraise}})</span>
                          <span  @click="jumpcomment(item)"><span></span>回复({{item.childEvaluate.length}})</span>
                      </div>
                  </div>
            </li>
          </ul>
      </van-tab>

    </van-tabs>
    <!-- 底部 -->
    <van-goods-action>
       <van-goods-action-icon :icon="list.collect==true?'star':'star-o'" :text="list.collect==true?'已收藏':'收藏'" :color="list.collect==true?'#f6b529':''" @click="collection"/>
       <van-goods-action-icon icon="cart-o" :badge="shoppinglength==0?'':shoppinglength" text="购物车"  @click="jumpshoppingcar"/>
       <van-goods-action-button type="warning" text="加入购物车" @click="addshoppingcar"  />
       <van-goods-action-button type="danger" text="立即购买"  @click="onClickButton"/>
    </van-goods-action>
    
    <div style="height: 1.3rem;"></div>
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Tab, Tabs } from 'vant';
import { GoodsAction, GoodsActionIcon, GoodsActionButton } from 'vant';
import { ImagePreview } from 'vant';
import { Toast } from 'vant';
import { Rate } from 'vant';
import { Lazyload } from 'vant';
import { Dialog } from 'vant';

Vue.use(Rate);
Vue.use(Toast);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(NavBar);
Vue.use(Lazyload);

import { detailsList,payment,pariseadmin,shoppingcat,cancelCollection,getauthor,getcomment,findShopCart,commodityCollection,focusUser} from "@api/servicemall.js";
import qs from "qs";
  export default {
    data(){
      return{
         id:'',
         active: 'sql-server',
         list:'',             //商品详情
         https:'https://p.writemall.com/mallFile/product/',
         show:false,
         authorId	:'',
         authorList:'',       //作者简介
         imgarr:[],          //作者简介图片数组
         swipearr:[],        //轮播图数组
         commentList:'',      //用户评论数组
         commentPic:[],       //用户图片数组
         isshow:1,
         shoppinglength:""
      }
    },
    created(){
       window.closeImagePreview = this.closeImagePreview
       this.id = this.$route.query.id
       this.getDetails()
       this.getshoppingcar()
    },
    methods: {
          /**
       * 判断是否为空
       * @param {} data
       */
      isEmpty(data) {
        if (typeof (data) == 'undefined') {
          return true;
        }
        if (typeof (data) == 'string') {
          if (data == "" || data == 'null' || data == 'undefined') {
            return true;
          }
        }
        if (typeof (data) == 'object' && !(data instanceof Date)) {
          if (data == undefined || data == null || data == {} || Object.keys(data).length == 0) {
            return true;
          }
        }
        return false;
      },
      isNotEmpty(data) {
        return !this.isEmpty(data);
      },
      //点赞
      async spotParise(item){
        const data = {
          praiseType:1,
          targetId:item.evaluateId
        }
        const result = await pariseadmin(data)
        if(result.code === 0){
          if(result.data){
            this.$toast("亲，点赞成功啦！")
          }else{
            this.$toast("亲，取消点赞噢！")
          }
           this.getcomments()
        }
      },
      //评论
      jumpcomment(item){
          this.$router.push({
            name:'comment',
            query:{
              productId:this.id,
              evaluateId:item.evaluateId
            }
          })
      },
      jumppersonal(id){
        this.$router.push({
          name:"Userpersonal",
          query:{
            id:id
          }
        })
      },

      //关注
      async followshow(){
         let param = {
           authorAccount: this.authorList.authorAccount
         }
        const result = await focusUser(qs.stringify(param));
        if (result.code === 0){
           this.getauthors()
        }
      },

      //关注
      clickFollowshow(){
         if(this.isshow == 1){
           this.followshow()
         }else{
          Dialog.confirm({
            title: '提示',
            message: '确定不再关注？',
            confirmButtonColor:'#4588FF',
            width:"250px"
          })
            .then(() => {
              this.followshow()
            })
            .catch(() => {
              // on cancel
            });
         }
      },
      //举报
      Report(item){
         this.$router.push({
          name:"complaint",
          query:{
            id:item.evaluateId,
            type:6
          }
        })
      },
       // 图片预览
      sceneImg(images,index) {
        const instance_before = ImagePreview({
            images:images, //需要预览的图片 URL 数组
            showIndex:true, //是否显示页码
            loop:false, //是否开启循环播放
            startPosition:index, //图片预览起始位置索引
            asyncClose: true,   //异步关闭
            closeable: true,
            onClose(){
              if(window.android){
                    let obj = {
                    action:'imagePreview',
                    data:{
                      type:0, 
                    }
                  }
                 window.android.postMessage(JSON.stringify(obj))
              }else if(window.webkit){
                    let obj = {
                    action:'imagePreview',
                    data:{
                      type:0, 
                    }
                  }
                 window.webkit.messageHandlers.sendMessageForVue.postMessage(JSON.stringify(obj))
              }else{
                return
              }
              
            }
          });
      
       this.$nextTick(()=>{
          let close = document.getElementsByClassName("van-image-preview__close-icon")[0]
          close.addEventListener("click",function(){
              instance_before.close()
          })
       })
      if(window.android){
        let obj = {
          action:'imagePreview',
          data:{
            type:1, 
          }
        }
        window.android.postMessage(JSON.stringify(obj))
      }else if(window.webkit){
        let obj = {
          action:'imagePreview',
          data:{
            type:1, 
          }
        }
         window.webkit.messageHandlers.sendMessageForVue.postMessage(JSON.stringify(obj))
      }else{
        return
      }
      this.$store.commit("instance_before",instance_before)
      this.closeImagePreview()
    },
      closeImagePreview:function(){
        let instance_before = this.$store.state.instance
        instance_before.close()
      },
      
      //跳转购物车
      jumpshoppingcar(){
          this.$router.push({
            name:'shoppingcar'
          })
      },
      //  返回
      onClickLeft() {
         this.$router.go(-1)
      },
      //  首页
      onClickRight() {
         this.$router.push({
           name:'ServiceMalladmin'
         })
      },
      // 立即购买
      async onClickButton(){
         const data = [
         {
            productId:this.id,
            productNum:1
         },
       ]
       const res = await payment(data)
       if(res.code === 0){
         sessionStorage.setItem("oid",res.data.oid)
         this.$router.push({
           name:'buynow'
         })
       }
      },
      // 收藏功能
      async collection(){
        if(this.list.collect){
          // 取消收藏
           const data = {
            productId:this.list.id
          }
          const result =  await cancelCollection(qs.stringify(data))
          if(result.code === 0){
            this.getDetails()
          }else{
             this.$toast(result.msg)
          }
        }else{
          //收藏
          const data = {
            productId:this.list.id
          }
          const result =  await commodityCollection(qs.stringify(data))
          if(result.code === 0){
            this.getDetails()
          }else{
            this.$toast(result.msg)
          }
        }
      },

      // 购物车
      addshoppingcar(){
         this.saveToShowpCart();
      },
      //加入购物车
      async saveToShowpCart(){
          const shopCart = {
            productId:this.id,
            productNum:1,
          }
          const result =  await shoppingcat(shopCart)
          if(result.code === 0 ){
            Toast('已加入购物车');
            this.getshoppingcar()
          }else{
            Toast("加入失败，请联系管理员 ")
          }
      },
      

      //获取详情
      async getDetails(){
        const result = await detailsList(this.id);
        if(result.code === 0 ){
          this.list = result.data
          this.authorId = result.data.authorId
          this.swipearr = []
          this.list.picList.map(item =>{
            this.swipearr.push(`${this.https}${item.picPath}`)
          })
          this.getauthors()
          this.getcomments()
        }
      },
      //获取商品作者
      async getauthors(){
        const data = {
          authorId:this.authorId
        }
        const result = await getauthor(qs.stringify(data))
        if(result.code === 0 ){ 
          if(result.data.length != 0){
            this.authorList = result.data

            if(result.data.focus == true && result.data.fans ==true){
              this.isshow = 3
            }else{
              if(result.data.fans){
                this.isshow = 2
              }else{
                this.isshow = 1
              }
            }

            this.authorList.authorPicList.map(item =>{
              this.imgarr.push(item.picPath)
            })

          }
        }
      },
      //获取用户评论
      async getcomments(){
         const data = {
           productId:this.id
         }
         const result = await getcomment(qs.stringify(data))
         if(result.code === 0 ){
           this.commentList = result.data 
           this.commentList.map(item=>{
               if(item.evaluateImg == undefined ||item.evaluateImg == null || item.evaluateImg.length == 0){
                  item.evaluateImg = []
               }else{
                 this.commentPic = item.evaluateImg.split(',')
                 delete item.evaluateImg;
                 item.evaluateImg = this.commentPic
               }
           })
         }
      },
       //请求购物车数据
      async getshoppingcar(){
        const result = await findShopCart();
        if(result.code === 0 ){
          this.shoppinglength = result.data.length
        }
      },
    },
    mounted(){
      window.closeImagePreview = this.closeImagePreview
    }
  }
</script>

<style lang="scss" scoped>

 .details{
      font-family: PingFang SC;
    ::v-deep .van-nav-bar__text{
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
    ::v-deep .van-nav-bar .van-icon{
      color: #333333;
    }
    ::v-deep .van-nav-bar__title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .van-nav-bar__content{
      height: 57px;
    }
    ::v-deep .topfix{
      position:fixed;
      z-index:100;
      width:100%;
      top:0;
    }
     .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 300px;
    text-align: center;
      img{
        height: 300px;
        width: 100%;
      }
    }
    .priceInformation{
      height: 125px;
      border-bottom: 10px solid #f8f8f8;
      p{
        margin-left: 19px;
      }
      p:first-of-type{
        margin-top: 10px;
        font-size: 23px;
        font-weight: bold;
        color: #EB441E;
        .active{
            font-size: 12px;
            font-weight: 400;
            text-decoration: line-through;
            color: #999999;
            margin-left: 10px;
        }
      }
      p:nth-of-type(2){
        margin-top: 17px;
        font-size: 15px;
        font-weight: bold;
        color: #333333;
      }
      p:last-of-type{
        margin-top: 15px;
        font-size: 13px;
        font-weight: 500;
        color: #333333;
        >span:first-of-type{
          color: #4587FF;
        }
        .follow{
          margin-left: 10px;
          width: 63px;
          height: 27px;
          background: #3783F3;
          border-radius: 27px;
          font-size: 11px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          display: inline-block;
          text-align: center;
          line-height: 27px;
          border: 1px solid transparent;
        }
        .Concerned{
           img{
             width: 11px;
             height: 8px;
             margin-top: 8px;
           }
            margin-left: 10px;
            width: 63px;
            height: 27px;
            background: #FFFFFF;
            border: 1px solid #666666;
            border-radius: 27px;
            font-size: 11px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            display: inline-block;
            text-align: center;
            line-height: 27px;
        }
        .mutual{
            img{
             width: 11px;
             height: 8px;
             margin-top: 8px;
           }
            margin-left: 10px;
            width: 65px;
            height: 27px;
            background: #FFFFFF;
            border: 1px solid #666666;
            border-radius: 27px;
            font-size: 11px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            display: inline-block;
            text-align: center;
            line-height: 27px;
        }
      }
    }
    ::v-deep .van-goods-action-button--last{
      border-top-right-radius:0px;
      border-bottom-right-radius:0px;
      margin-right:0px;
    }
     ::v-deep .van-goods-action-button--first{
      border-top-left-radius:0px;
      border-bottom-left-radius:0px;
      margin-left:0px;
    }
    ::v-deep .van-goods-action-button{
       width: 122px;
       height: 49px;
    }
    ::v-deep .van-button__text{
      font-size: 15px;
      font-family: PingFang SC;
      color: #FFFFFF;
    }
    .text{
      margin-top: 20px;
      margin-left: 21px;
      margin-right: 21px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }
    .author{
      video{
        width: 334px;
        height: 170px;
        opacity: 0.7;
        border-radius: 10px;
        margin-left: 20px;
      }
      .images{
         width: 335px;
         margin-left: 20px;
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between;
         img{
           width: 160px;
           height: 117px;
           border-radius: 10px;
           margin-top: 10px;
         }
      }
    }
    .kong{
      width: 100%;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
      height: 100px;
      line-height: 100px;
    }
    .list{
      margin-top: 21px;
      .item{
        .item-header{
          height: 33px;
          margin-left: 20px;
          position: relative;
          p:first-of-type{
            position: absolute;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #4E4E4E;
            left: 43px;
          }
          p:last-of-type{
            position: absolute;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            right: 19px;
          }
          ::v-deep .van-rate{
            position: absolute;
            left: 43px;
            top: 20px;
          }
        }
        .item-content{
          margin-top: 14px;
          margin-left: 20px;
          .txt{
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #626262;
          }
          .imgpic{
            margin-top: 15px;
            img{
              width: 107px;
              height: 107px;
              margin-left: 10px;
            }
            img:first-of-type{
              margin-left: 0;
            }
          }
        }
        .item-footer{
          position: relative;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #888888;
          margin-top: 15px;
          height: 20px;
          .right {
            position: absolute;
            right: 19px;
            >span{
              margin-left: 10px;
            }
            >span:nth-of-type(2){
              .red{
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                    background: url('../assets/image/red-zan.png') no-repeat;
                    background-size: 100% 100%;
                    display: inline-block;
                    transform: translateY(2px);
                  }
                  .hui{
                    width: 15px;
                    height: 15px;
                     margin-right: 5px;
                    background: url('../assets/image/comment-dianzan.png') no-repeat;
                    background-size: 100% 100%;
                    display: inline-block;
                    transform: translateY(2px);
                  }  
            }
            >span:last-of-type{
               span:first-of-type{
                display: inline-block;
                width: 16px;
                height: 16px;
                background: url("../assets/image/comment-pinglun.png") no-repeat;
                background-size: 100% 100%;
                margin-right: 8px;
                transform: translateY(2px);
              }
            }
          }
        }
      }
    }
    .top{
      margin-top: 20px;
    }
 }
 ::v-deep .van-image--round{
    width: 33px;
    height: 33px;
     position: absolute;
}
</style>